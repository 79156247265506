import {get, post, put,Delete} from "@/api/axios";

export const list = params => get('/web/shop/spec/child/list', params);

export const add = params => post('/web/shop/spec/child/add', params);

export const deleteSpec = params => Delete('/web/shop/spec/child/delete/' + params.id, '');

export const view = params => get('/web/shop/spec/child/view/' + params.id, '');

export const update = params => put('/web/shop/spec/child/update', params);